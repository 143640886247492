<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-5">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logos/logo.svg')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              PesaSave
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <!-- <p class="d-flex text-xl font-weight-semibold text--primary mb-2 align-center justify-center">
            Welcome to the world of Saving 🚀
          </p> -->
          <p class="mb-2">
            Register for a free account to start saving
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="form.body.name"
              outlined
              label="Name"
              placeholder="JohnDoe"
              :error-messages="form.errors.name"
              dense
            ></v-text-field>

            <v-text-field
              v-model="form.body.email"
              outlined
              label="Email"
              placeholder="john@example.com"
              type="email"
              :error-messages="form.errors.email"
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.body.phone"
              outlined
              label="Phone Number"
              placeholder="+256 788 888 888"
              :error-messages="form.errors.phone"
              dense
            ></v-text-field>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.body.date_of_birth"
                  label="Date of birth"
                  hint="MM/DD/YYYY format"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  dense
                  :error-messages="form.errors.date_of_birth"
                ></v-text-field>
              </template>
              <v-date-picker v-model="form.body.date_of_birth" no-title @input="menu2 = false"></v-date-picker>
            </v-menu>
            <v-select
              dense
              :error-messages="form.errors.gender"
              v-model="form.body.gender"
              :items="genderOptions"
              label="Gender"
              outlined
            ></v-select>

            <v-text-field
              v-model="form.body.password"
              hint="atleast 6 characters"
              :rules="[rules.required, rules.min]"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              :error-messages="form.errors.password"
              @click:append="isPasswordVisible = !isPasswordVisible"
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.body.password_confirmation"
              :rules="[rules.required, rules.min]"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Confirm password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              :error-messages="form.errors.password_confirmation"
              @click:append="isPasswordVisible = !isPasswordVisible"
              dense
            ></v-text-field>

            <v-btn block color="primary" class="mt-6" :loading="loading" @click="register">
              Sign Up
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2"> Already have an account? </span>
          <router-link :to="{ name: 'auth-login' }">
            Sign in instead
          </router-link>
        </v-card-text>

        <!-- divider -->
        <v-card-text v-if="false" class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text>

        <!-- social link -->
        <v-card-actions v-if="false" class="d-flex justify-center">
          <v-btn v-for="link in socialLink" :key="link.icon" icon class="ms-1">
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import axios from 'axios'
import { AbilityBuilder } from '@casl/ability'

// import { ref } from '@vue/composition-api'
const socialLink = [
  {
    icon: mdiFacebook,
    color: '#4267b2',
    colorInDark: '#4267b2',
  },
  {
    icon: mdiTwitter,
    color: '#1da1f2',
    colorInDark: '#1da1f2',
  },
  {
    icon: mdiGithub,
    color: '#272727',
    colorInDark: '#fff',
  },
  {
    icon: mdiGoogle,
    color: '#db4437',
    colorInDark: '#db4437',
  },
]
export default {
  data() {
    return {
      menu2: false,
      isPasswordVisible: false,
      loading: false,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 6 || 'Min 6 characters',
        emailMatch: () => "The email and password you entered don't match",
      },
      form: {
        body: {
          name: '',
          email: '',
          phone: '',
          password: '',
          date_of_birth: '',
          gender: '',
          password_confirmation: '',
        },

        errors: {},
        remember: false,
        fetchUser: false,
        autoLogin: true,
        staySignedIn: true,
      },
      genderOptions: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
      ],
    }
  },
  methods: {
    errors(res) {
      this.form.errors = res.data.errors // Object.fromEntries(res.data.errors.map(item => [item.field, item.msg]))
    },
    register() {
      this.loading = true
      this.$auth
        .register({
          data: this.form.body, // Axios
          url: '/auth/register', // URL
          redirect: null,
          remember: true, // this.form.remember ? '{"name": "Redirect"}' : null,
          fetchUser: this.form.fetchUser,

          // autoLogin: this.form.autoLogin,
          staySignedIn: this.form.staySignedIn,
        })
        .then(res => {
          const { token } = res.data.data
          // eslint-disable-next-line dot-notation
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
          this.$auth.token(null, token, true)
          this.$http.get('me').then(resp => {
            const { data } = resp.data
            this.$auth.user({
              id: data.id,
              name: data.name,
              phone: data.phone,
              email: data.email,
              type: 'user',
            })
            this.updateAbility(data.permission)
            this.$router.push({ name: 'dashboard' })
          })
        })
        .then(null, res => {
          this.errors(res.response)
          this.loading = false
        })
    },
    updateAbility(permissions) {
      const { can, rules } = new AbilityBuilder()
      permissions.forEach(permission => {
        can(permission)
      })

      this.$ability.update(rules)
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
